export const CHECK_IN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  EXPIRED: 'EXPIRED',
  CANCELED: 'CANCELED',
};

export const WALLET_TYPES = {
  BONUS_WALLET: 'BONUS',
  // NORMAL_WALLET: 'NORMAL',
};

export const PLAYER_REWARD_STATUS = {
  ISSUED: 'ISSUED',
  EXPIRED: 'EXPIRED',
  USED: 'USED',
  COMPLETED: 'COMPLETED',
};

export const CHECK_IN_TYPES = {
  CUMULATIVE: 'CUMULATIVE',
  CONSECUTIVE: 'CONSECUTIVE',
};
